/* @flow */
/* Generates link for subnavigation */
import type { Node } from 'react';

import { Component } from 'react';
import { Box } from '@eyeem-ui/atoms';
import T from '../translate.jsx';
import Link from '../link/';
import { abbreviate } from '../../../helpers/tools';

type Props = {
  navItem: {
    count: number,
    active: boolean,
    href: string,
    external: boolean,
    mobileCustomItem: Node,
    trackingInfo: {},
    label: string,
    onClick: Function,
    scrollToY: Number,
  },
  className?: string,
  isLast?: boolean,
  isMobile?: boolean,
};

const Container = ({ children, isLast, isMobile }) => {
  return isMobile ? (
    <Box mb={isLast ? 0 : 3} pl={2}>
      {children}
    </Box>
  ) : (
    <>{children}</>
  );
};

class SubnavigationLink extends Component<Props> {
  component() {
    if (this.props.navItem.external) {
      return 'a';
    }
    if (this.props.navItem.active) {
      return 'p';
    }
    return Link;
  }

  render() {
    // on mobile we don't want to render the active nav item, as this
    // is already the title of the Collapsible
    if (this.props.isMobile && this.props.navItem.active) {
      return null;
    }

    // sometimes we have a custom item just for the mobile case
    if (this.props.isMobile && this.props.navItem.mobileCustomItem) {
      return this.props.navItem.mobileCustomItem;
    }

    const abbreviatedCount =
      this.props.navItem.count &&
      abbreviate(parseInt(this.props.navItem.count, 10));

    return (
      <Container isLast={this.props.isLast} isMobile={this.props.isMobile}>
        <T
          {...this.props.navItem.trackingInfo}
          component={this.component()}
          className={this.props.className}
          noTranslate={!!this.props.navItem.label}
          onClick={this.props.navItem.onClick}
          scrollToY={
            !this.props.navItem.external &&
            !this.props.navItem.active &&
            this.props.navItem.scrollToY
          }
          with={{
            count: this.props.navItem.count,
            abbreviatedCount,
          }}
          href={
            !this.props.navItem.active ? this.props.navItem.href : undefined
          }>
          {this.props.navItem.translationKey || this.props.navItem.label}
        </T>
      </Container>
    );
  }
}

export default SubnavigationLink;
