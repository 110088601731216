import { connect } from 'react-redux';
import Subnavigation from './subnavigation.jsx';

import { getIsLoginViaParam } from '../../../reducers/';

const mapStateToProps = (state) => ({
  isLoginViaParam: getIsLoginViaParam(state),
});

export default connect(mapStateToProps)(Subnavigation);
