/* @flow */
import t from 'counterpart';
import { Box } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Dropdown } from '@eyeem-ui/organisms';

type Props = {
  isOwnProfile: boolean,
  navigate: Function,
  paginatableName: string,
  showContentFilterDropdown: boolean,
  userNickname: string,
};

const ProfileContentFilterDropdown = ({
  isOwnProfile,
  navigate,
  paginatableName,
  showContentFilterDropdown,
  userNickname,
}: Props) => {
  let selectedItem = 0;
  if (paginatableName === 'marketPhotos') {
    selectedItem = 1;
  } else if (paginatableName === 'partnerPhotos' && isOwnProfile) {
    selectedItem = 2;
  }

  const dropdownItems = [
    {
      onSelect: () => {
        navigate(`/u/${userNickname}/photos`);
      },
      text: t('user.filterItems.photos'),
    },
    {
      onSelect: () => {
        navigate(`/u/${userNickname}/market`);
      },
      text: t('user.filterItems.market'),
    },
  ];

  if (isOwnProfile) {
    dropdownItems.push({
      onSelect: () => {
        navigate(`/u/${userNickname}/partner`);
      },
      text: t('user.filterItems.partner'),
    });
  }

  return (
    <>
      {showContentFilterDropdown && (
        <ThemeProvider>
          <Box>
            <Dropdown
              selectedItem={selectedItem}
              disableSelection
              items={dropdownItems}
            />
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default ProfileContentFilterDropdown;
