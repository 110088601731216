/* @flow */
/**
 * Subnavigation as used on static pages and album/profile views.
 */
import type { Node } from 'react';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import t from 'counterpart';
import classnames from 'classnames';

import styled from '@emotion/styled';
import { Box } from '@eyeem-ui/atoms';

import { useInView } from 'react-intersection-observer';

import baseTheme from '../../../helpers/theme';
import Collapsible from '../collapsible.jsx';
import SubnavigationLink from './subnavigationLink.jsx';
import { abbreviate } from '../../../helpers/tools';
import { NAVBAR_HEIGHT } from '../../../constants/misc';
import ProfileContentFilterDropdown from '../../profile/profileContentFilterDropdown';

const StyledSticky = styled.div`
  position: sticky;
  top: ${(props) => props.topOffset}px;
`;

const StyledDropdownWrapper = styled(Box)`
  position: absolute;
  right: 0;
  top: 12px;
`;

const IntersectionHelper = styled.span``;

function Subnavigation(props: {
  centered?: boolean,
  isLoginViaParam?: boolean,
  isMobile?: boolean,
  isOwnProfile: boolean,
  navItems: $ReadOnlyArray<Object>,
  navigate: Function,
  night?: boolean,
  noCollapsible?: boolean,
  noNavbar?: boolean,
  paginatableName: string,
  showContentFilterDropdown: boolean,
  sideInfoLeft?: Node,
  sideInfoRight?: Node | boolean,
  userNickname: string,
}) {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: `-${baseTheme.navHeight} 0px 0px 0px`,
  });

  const noNavbar = props.isLoginViaParam || props.noNavbar;

  const activeNavItem = find(props.navItems, { active: true });
  const activeNavItemIndex = findIndex(props.navItems, { active: true });

  const arrangedNavItems = props.navItems;

  const showCollapsible = props.isMobile && !props.noCollapsible;

  // when on mobile we need the active item to be the first one
  if (showCollapsible) {
    arrangedNavItems.splice(activeNavItemIndex, 1);
    arrangedNavItems.unshift(activeNavItem);
  }

  const content = (
    <div
      className={classnames({
        lp_maxWidth: !showCollapsible,
      })}>
      {props.sideInfoLeft}
      {!showCollapsible &&
        props.navItems.map((navItem, i) => (
          <SubnavigationLink
            key={navItem.href}
            navItem={navItem}
            className={classnames('g_link subnav_item typo_cap', {
              'g_link-white': props.night,
              'g_link-grey': !props.night,
              'subnav_item-largerMargin': props.centered,
              'g_link-active subnav_item-active': navItem.active,
              'subnav_item-last': i === props.navItems.length - 1,
            })}
          />
        ))}
      {props.sideInfoRight}
      {showCollapsible && activeNavItem && (
        <Collapsible
          onWhite
          className="subnav_item"
          titleClass="typo_pStatic"
          titleCollapse={t(activeNavItem.translationKey)}
          titleExpand={t(activeNavItem.translationKey)}
          label={t(activeNavItem.translationKey, {
            count: activeNavItem.count,
            abbreviatedCount:
              activeNavItem.count &&
              abbreviate(parseInt(activeNavItem.count, 10)),
          })}>
          {arrangedNavItems.map((navItem, i) => (
            <SubnavigationLink
              isLast={i === props.navItems.length - 1}
              isMobile
              key={navItem.href}
              navItem={navItem}
              className={classnames('g_link typo_pStatic', {
                'g_link-white': props.night,
                'g_link-grey': !props.night,
              })}
            />
          ))}
        </Collapsible>
      )}
    </div>
  );

  const className = classnames('subnav', {
    'text-centered': props.centered && !showCollapsible,
    'subnav-night': props.night,
    'subnav-noNavbar': noNavbar,
    'subnav-fixed': !inView,
  });

  return (
    <>
      <IntersectionHelper ref={ref} />

      <StyledSticky
        topOffset={noNavbar ? 0 : NAVBAR_HEIGHT}
        className={className}>
        {content}
        {!props.isMobile && (
          <StyledDropdownWrapper mr={4}>
            <ProfileContentFilterDropdown
              isOwnProfile={props.isOwnProfile}
              userNickname={props.userNickname}
              navigate={props.navigate}
              paginatableName={props.paginatableName}
              showContentFilterDropdown={props.showContentFilterDropdown}
            />
          </StyledDropdownWrapper>
        )}
      </StyledSticky>
    </>
  );
}

export default Subnavigation;
