/* @flow */

import type { Node } from 'react';

import { Component } from 'react';
import { styleUtils } from 'eyeem-components';
import styled, { css } from 'styled-components';
import t from 'counterpart';

// eslint-disable-next-line no-unused-vars
const StyledArrowContainer = styled(({ isOpen, onWhite, ...rest }) => (
  <span {...rest} />
))`
  float: right;
  width: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${(props) => (props.isOpen ? '0' : '10px')};
  background-image: url('/node-static/img/dropdown-arrow${(props) =>
    props.onWhite ? '' : '-white'}.svg');
  margin-top: 6px;
  transition: background-size 0.2s ease-out;
  height: ${(props) => (props.isOpen ? '1px' : '5px')};

  &:after {
    content: ' ';
    height: 2px;
    transform: scale(${(props) => (props.isOpen ? '1' : '0')});
    transition: transform 0.2s ease-out;
    display: block;

    background-color: ${(props) =>
      props.onWhite
        ? styleUtils.fromTheme('colors.blacks.black1')
        : styleUtils.fromTheme('colors.whites.white')};
  }
`;

// eslint-disable-next-line no-unused-vars
const StyledLinkButton = styled(({ onWhite, isOpen, ...rest }) => (
  <button {...rest} /> // eslint-disable-line
))`
  width: 100%;
  margin: 0;
  padding: ${(props) => (props.onWhite ? '12px 12px 12px 8px' : '12px 0px')};
  text-align: left;
  font-size: 14px;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: ${(props) =>
    props.onWhite
      ? styleUtils.fromTheme('colors.blacks.black1')
      : styleUtils.fromTheme('colors.whites.white')};
  ${(props) => (props.isOpen ? 'margin-bottom: 10px' : undefined)};
`;

// eslint-disable-next-line no-unused-vars
const StyledCollapsibleWrapper = styled(({ onWhite, ...rest }) => (
  <div {...rest} />
))`
  width: 100%;
  ${(props) =>
    props.onWhite
      ? css`
          border-top: 1px solid
            ${styleUtils.fromTheme('colors.uncategorized.unnamed15')};
        `
      : css`
          border-bottom: 1px solid
            ${styleUtils.fromTheme('colors.blacks.black5')};
        `}
`;

type Props = {
  // render it as open.
  open: boolean,

  onWhite: boolean,

  label: Node,

  // [title] for expand trigger before expand
  titleExpand?: string,

  // [title] for expand trigger before collapse
  titleCollapse?: string,

  // Should the component be uncontrolled,
  // ie should its parent handle its open/close state
  uncontrolled: boolean,

  // toggle callback
  onToggle: Function,

  // white theme
  onWhite: boolean,

  // class used for title element
  titleClass: string,
  children: Node,
  className?: string,
};

type State = {
  open: boolean,
};

class Collapsible extends Component<Props, State> {
  constructor(props: Props, context: mixed) {
    super(props, context);
    this.state = {
      // the state is only used when the component controls itself
      open: props.open,
    };
  }

  handleHeaderClick = () => {
    if (this.props.uncontrolled) {
      return this.props.onToggle();
    }
    return this.setState((prevState) => ({ open: !prevState.open }));
  };

  getTitleCollapse = () =>
    this.props.titleCollapse || t('collapsible.collapse');

  getTitleExpand = () => this.props.titleExpand || t('collapsible.expand');

  render() {
    const isOpen = this.props.uncontrolled
      ? Boolean(this.props.open)
      : Boolean(this.state.open);

    const title = isOpen ? this.getTitleCollapse() : this.getTitleExpand();

    return (
      <StyledCollapsibleWrapper
        onWhite={this.props.onWhite}
        className={this.props.className}>
        <StyledLinkButton
          type="button"
          isOpen={isOpen}
          onWhite={this.props.onWhite}
          title={title}
          onClick={this.handleHeaderClick}>
          {this.props.label}
          <StyledArrowContainer isOpen={isOpen} onWhite={this.props.onWhite} />
        </StyledLinkButton>
        {isOpen && <div key="collapsible_inner">{this.props.children}</div>}
      </StyledCollapsibleWrapper>
    );
  }
}

Collapsible.defaultProps = {
  // the prop `open` is only used when the component is uncontrolled
  // otherwise it is the default state
  open: false,
};

export default Collapsible;
